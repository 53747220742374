import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const dateInputs = this.element.querySelectorAll('input[type="datetime-local"]');
    const today = new Date().toISOString().split('T')[0];

    dateInputs.forEach(input => {
      input.setAttribute('min', today);
    });
  }
}
