import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expiresAt", "expiresAtLabel", "expirationCheckbox"];

  connect() {
    this.initializeState(); // Initialize the state based on the checkbox
  }

  initializeState() {
    this.toggleExpiration({ target: this.expirationCheckboxTarget });
  }

  toggleExpiration(event) {
    const expirationChecked = event.target.checked;

    this.expiresAtTargets.forEach(target => {
      if (expirationChecked) {
        target.classList.remove('hidden');
        // Loop through each select element within the expiresAt target and remove disabled attribute
        target.querySelectorAll('select').forEach(select => select.disabled = false);
      } else {
        target.classList.add('hidden');
        // Loop through each select element within the expiresAt target and add disabled attribute
        target.querySelectorAll('select').forEach(select => select.disabled = true);
      }
    });

    this.expiresAtLabelTargets.forEach(target => {
      if (expirationChecked) {
        target.classList.remove('hidden');
      } else {
        target.classList.add('hidden');
      }
    });
  }
}